import { template as template_80cc46c4ee14405289584365a90aff88 } from "@ember/template-compiler";
const WelcomeHeader = template_80cc46c4ee14405289584365a90aff88(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
