import { template as template_afd5e213e07f4e34915f2a81444f2626 } from "@ember/template-compiler";
const SidebarSectionMessage = template_afd5e213e07f4e34915f2a81444f2626(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
